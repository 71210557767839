import { CoreModule } from '@abp/ng.core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { PrimengSharedModule } from './primeng-shared.module';

import { ValidationComponent } from './dft/component/validation-messages.component';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import { LabelValidationComponent } from './dft/component/lable-validate.component';
import { PopupConfirmComponent } from './dft/component/popup-confirm/popup-confirm.component';
import { TienTePipe } from './dft/pipe/tiente.pipe';
import { NgxCurrencyDirective } from 'ngx-currency';
@NgModule({
  declarations: [
    ValidationComponent,
    LabelValidationComponent,
    PopupConfirmComponent,
    TienTePipe,
  ],
  imports: [
    CoreModule,
    NgbDropdownModule,
    TableModule,
    PrimengSharedModule,
    TableModule,
    RouterModule,
    NgxCurrencyDirective
  ],
  exports: [
    CoreModule,
    NgbDropdownModule,
    PrimengSharedModule,
    ValidationComponent,
    LabelValidationComponent,
    PopupConfirmComponent,
    TableModule,
    RouterModule,
    TienTePipe,
    NgxCurrencyDirective
  ],
  providers: []
})
export class SharedModule { }
