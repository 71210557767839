import { Component, Injector, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ConfigStateService } from '@abp/ng.core';
import { AppComponentBase } from './shared/app-component-base';

@Component({
  selector: 'app-root',
  template: `
   <router-outlet></router-outlet>
  `,
})
export class AppComponent extends AppComponentBase implements OnInit {

  topbarTheme = 'light';

  menuTheme = 'dim';

  layoutMode = 'light';

  menuMode = 'static';

  isRTL = false;

  inputStyle = 'outlined';

  ripple: boolean;
  constructor(
    injector: Injector,
    private primengConfig: PrimeNGConfig) {
    super(injector);
  }
  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation({
      emptyMessage: this.L('::Common:NoData'),
    });
  }
}