import { AuthService, ConfigStateService, LocalizationService } from "@abp/ng.core";
import { Injector } from "@angular/core";
import { MessageService } from "primeng/api";
import { catchError, finalize, throwError } from "rxjs";

export abstract class AppComponentBase {
    localizationService: LocalizationService;
    messageService: MessageService;
    authService: AuthService;
    configStateService: ConfigStateService;

    constructor(injector: Injector) {
        this.localizationService = injector.get(LocalizationService);
        this.messageService = injector.get(MessageService);
        this.authService = injector.get(AuthService);
        this.configStateService = injector.get(ConfigStateService);
    }

    get hasLoggedIn(): boolean {
        return this.authService.isAuthenticated;
    }

    getCurrentUser() {
        return this.configStateService.getOne('currentUser');
    }

    showCreateSuccessMessage() {
        this.showSuccessMessage(this.L('::Message:CreateMessage'));
    }

    showUpdateSuccessMessage() {
        this.showSuccessMessage(this.L('::Message:UpdateMessage'));
    }

    showDeleteSuccessMessage() {
        this.showSuccessMessage(this.L('::Message:DeleteMessage'));
    }

    showSuccessMessage(message: string) {
        this.messageService.clear('tms');
        this.messageService.add({
            key: 'tms',
            severity: 'success',
            summary: this.localizationService.instant('::Message:Success'),
            detail: message,
        });
    }

    showInfoMessage(message: string) {
        this.messageService.clear('tms');
        this.messageService.add({
            key: 'tms',
            severity: 'info',
            summary: this.localizationService.instant('::Message:Info'),
            detail: message,
        });
    }

    showWarningMessage(message: string) {
        this.messageService.clear('tms');
        this.messageService.add({
            key: 'tms',
            severity: 'warn',
            summary: this.localizationService.instant('::Message:Warn'),
            detail: message,
        });
    }

    showErrorMessage(message: string) {
        this.messageService.clear('tms');
        this.messageService.add({
            key: 'tms',
            severity: 'error',
            summary: this.localizationService.instant('::Message:Error'),
            detail: message,
        });
    }

    L(key: string) {
        return this.localizationService.instant(key);
    }

    logout() {
        this.authService.logout().subscribe(()=>{
            location.reload();
        });
    }

    handlerError(err) {
        debugger
        if (err && err.error.error.message.includes('Your request is not valid!')) {
            this.showErrorMessage(this.L('::CreatorDonate:AddWishList:SomeItemsHaveNotBeenSet'));
        }
        else {
            this.showErrorMessage(err.error.error.message);
        }
    }
}