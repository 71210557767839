import { Environment } from '@abp/ng.core';

const baseUrl = 'MY_APP_CONFIG';
export const environment = {
  production: true,
  sentry: {
    dsn: 'MY_SENTRY_DNS',
    environment: 'MY_SENTRY_ENVIRONMENT',
    release: 'MY_SENTRY_RELEASE'
  },
  application: {
    baseUrl,
    name: 'CreatorDonate',
    logoUrl: '',
  },
  oAuthConfig: {
    issuer: 'MY_SWAGGER_CONFIG' + '/',
    redirectUri: baseUrl,
    clientId: 'CreatorDonate_App',
    responseType: 'form',
    scope: 'offline_access CreatorDonate',
    requireHttps: true,
  },
  apis: {
    default: {
      url: 'MY_SWAGGER_CONFIG',
      rootNamespace: 'CreatorDonate',
    },
  },
  firebase: {
    apiKey: "AIzaSyBOEPMQw6mQT0w8C2KzpTzcdW0pvbHFLX8",
    authDomain: "gipt-395204.firebaseapp.com",
    databaseURL: "https://gipt-395204-default-rtdb.firebaseio.com",
    projectId: "gipt-395204",
    storageBucket: "gipt-395204.appspot.com",
    messagingSenderId: "395732323816",
    appId: "1:395732323816:web:961e459726d7d06c2b285d",
    measurementId: "G-Y2F4NM9KLB"
  },
  twitch: {
    clientId: '407ewipe4gwol9hvsyemq66ft3prep',
    redirectUri: baseUrl + '/auth/sign-in',
    responseType: 'token', // or 'code' if you're using Authorization Code flow
  }
} as Environment;
